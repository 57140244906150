<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showRecord" />
      <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  computed: {
    encrypt() {
      let id = this.params.data.id;
      return this.$secure.encrypt(id);
    }
  },
  methods: {
    showRecord(){
      this.params.showItem(this.params.data)
    },

    editRecord () {
      this.params.editItem(this.params.data);
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure to delete incoming letter by subject "${this.params.data.perihal}" and receiver ${this.params.data.penerima} ?`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.$store.dispatch("incomingLetter/removeItem", this.params.data.id)
        .then((resp)   => {
          this.$store.dispatch('incomingLetter/fetchItems').catch(err => {
            this.$vs.notify({
              title:'Opps something error',
              text: err.data.message,
              color:'danger'
            })
          })

          this.showDeleteSuccess(resp)
        })
        .catch(err => {
          this.showDeleteError(err)
        })
    },
    showDeleteSuccess (resp) {
      this.$vs.notify({
        color: 'success',
        title: 'Delete success',
        text: resp.data.message
      })
    },
    showDeleteError (err) {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    }
  }
}
</script>
