<template>

  <div id="page-user-list" v-show="loaded">

    <div class="vx-row mb-4 px-4">
      <div class="vx-card p-6">

        <!-- Header -->
        <div class="flex justify-between flex-wrap items-center">
          <div>
            <h4 class="mb-2">Rekap Absen Bulan {{ date.month.text }}</h4>
          </div>

          <div style="display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 16px; align-items: center;">
            <v-select style="width: 140px;" v-model="date.month" :clearable="false" label="text" :options="monthOptions"></v-select>
            <v-select v-model="date.year" :clearable="false" :options="yearOptions"></v-select>
          </div>

        </div>


        <!-- AgGrid Table -->
        <ag-grid-vue
          ref="agGridTable"
          :components="components"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="itemsData"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl">
        </ag-grid-vue>

        <!-- Pagination -->
        <div class="vx-row">
          <div class="vx-col sm:w-1/2 w-full">
            <!-- ITEMS PER PAGE -->
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ itemsData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : itemsData.length }} of {{ itemsData.length }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>

                <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                  <span>25</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                  <span>30</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <!-- PAGINATION -->
            <vs-pagination
              :total="totalPages"
              :max="7"
              v-model="currentPage" />
          </div>
        </div>

      </div>
    </div>

    <!-- Chart per month -->
    <div class="vx-row px-4 mb-4">
      <div class="vx-card p-6">
        <!-- Header -->
        <h4 class="mb-3">Grafik Absen Bulan {{ date.month.text }}</h4>

        <!-- Chart -->
        <vue-apex-charts type="bar" height="266" :options="chart.perMonth.options" :series="chart.perMonth.series" />
      </div>
    </div>

    <!-- Chart per year -->
    <div class="vx-row px-4">
      <div class="vx-card p-6">
        <!-- Header -->
        <h4 class="mb-3">Grafik Absen Tahun {{ date.year }}</h4>

        <!-- Chart -->
        <vue-apex-charts type="bar" height="266" :options="chart.perYear.options" :series="chart.perYear.series" />
      </div>
    </div>

    <!--  Popup Brand  -->
    <vs-popup classContent="popup-example" :title="dynamicTitle" :active.sync="popup">

      <div class="vx-row" v-show="mode === 'detail'">
        <div class="vx-col md:w-1/1 w-full mb-3">
          <span ref="description"></span>
        </div>
      </div>

      <div class="vx-row" v-show="mode !== 'detail'">
        <div class="vx-col md:w-1/1 w-full mb-3">
          <vs-radio class="mr-2" v-model="form.status" vs-value="Alfa">
            Alfa
          </vs-radio>
          <vs-radio class="mr-2" v-model="form.status" vs-value="Sakit">
            Sakit
          </vs-radio>
          <vs-radio class="mr-2" v-model="form.status" vs-value="Izin">
            Izin
          </vs-radio>
          <vs-radio class="mr-2" v-model="form.status" vs-value="Cuti">
            Cuti
          </vs-radio>
          <vs-radio class="mr-2" v-model="form.status" vs-value="Libur">
            Libur
          </vs-radio>
          <vs-radio class="mr-2" v-model="form.status" vs-value="Lainnya">
            Lainnya
          </vs-radio>
        </div>

        <div class="vx-col md:w-1/1 w-full">
          <label for="description" class="vs-input--label">{{$i18n.t('Description')}}</label>
          <vs-textarea id="description" rows="6" class="w-full mb-3" v-model="form.description" />
        </div>
      </div>

      <div class="mt-5" v-show="mode !== 'detail'">
        <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
          {{ $i18n.t('Save') }}
        </vs-button>
      </div>

    </vs-popup>
  </div>

</template>

<script>

const colors = {
  danger  : '#EF6F6C',
  warning : '#F5B841',
  primary : '#00A651',
  success : '#22577A',
  dark    : '#22577A',
};

import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import axios from '@/axios';

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererFile from './cell-renderer/CellRendererFile.vue'


export default {
  components: {
    AgGridVue,
    vSelect,
    VueApexCharts,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererFile
  },
  data () {
    return {
      loaded: false,
      searchQuery: '',
      yearOptions: [],
      monthOptions: [
          { text: 'Januari', value: 1 },
          { text: 'Februari', value: 2 },
          { text: 'Maret', value: 3 },
          { text: 'April', value: 4 },
          { text: 'Mei', value: 5 },
          { text: 'Juni', value: 6 },
          { text: 'Juli', value: 7 },
          { text: 'Agustus', value: 8 },
          { text: 'September', value: 9 },
          { text: 'Oktober', value: 10 },
          { text: 'November', value: 11 },
          { text: 'Desember', value: 12 }
      ],
      form: {},
      popup: false,
      mode: 'add',
      itemSelected: false,
      date: {
        month:{
          text: '',
          value: null
        },
        year: ''
      },
      // Apex charts
      chart:{
        perMonth: {
          options:{},
          series:[]
        },

        perYear: {
          options:{},
          series:[]
        }
      },
      // AgGrid
      gridApi: null,
      gridOptions: {},
      itemsData: [],
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('Date'),
          field: 'date',
          filter: true,
          headerCheckboxSelectionFilteredOnly: true,
        },
        {
          headerName: this.$i18n.t('Day'),
          field: 'day',
          filter: true,
        },
        {
          headerName: this.$i18n.t('Employee'),
          field: 'employee.name',
          filter: true,
        },
        {
          headerName: this.$i18n.t('Status'),
          field: 'status',
          filter: true,
          width: 120,
          cellRendererFramework: 'CellRendererStatus',
          cellClass: 'text-center',
        },
        {
          headerName: 'Action',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            editItem: this.editItem.bind(this),
            showItem: this.showItem.bind(this)
          },
          headerClass: 'text-center',
          cellClass: 'text-center'
        }
      ],
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      // if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      // else return 0

      if(this.gridApi) return Math.ceil(this.itemsData.length / this.gridApi.paginationGetPageSize())
      else return
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },

    // get the selected month
    selectedMonth(){
      return this.date.month;
    },

    selectedYear(){
      return this.date.year;
    },

    dynamicTitle(){
      if(this.mode !== 'detail'){
        return `${this.mode} item`
      }
      return this.$i18n.t('Description')
    }
  },
  methods: {
    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    getData () {
      let params = {
          month: this.date.month.value,
          year: this.date.year,
      };

      axios.get('/absents/not-present', {params}).then(resp => this.itemsData = resp.data.not_present_date.map((data) => {
        data.date = data.date.split('-').reverse().join('/');
        return data;
      }) ).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
      .finally(() => {
        this.onLoaded();
      })
    },
    showItem(item){
      this.popup = true
      this.mode = 'detail'

      this.$refs.description.innerText = item.description === null ? '-' : item.description
    },
    addItem () {
      this.popup = true;
      this.mode = 'add';
    },
    editItem (item) {
      this.popup = true;
      this.mode = 'edit';
      this.itemSelected = item;

      this.form = {
        nik: item.employee.nik,
        tanggal: item.date.split('/').reverse().join('-'),
        status: item.status,
        description: item.description
      };
    },
    storeItem () {
      if(this.mode == 'edit'){
        let body = this.form

        axios.post('/absents/not-present/update-status', body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.getData();
          });
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })

        this.mode = false;
      }
    },

    getChartPerMonth(){
      axios.get(`/absents/not-present/chart?year=${this.selectedYear}&month=${this.selectedMonth.value}`)
      .then(({data}) => {
        this.chart.perMonth.options = {
          chart: {
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: data.categories
          },
          colors: Object.values(colors),
        }

        this.chart.perMonth.series = data.series;
      })
      .catch((error) => { console.log(error) })
    },

    getChartPerYear(){
      axios.get(`/absents/not-present/chart?year=${this.selectedYear}`)
        .then(({data}) => {
          this.chart.perYear.options = {
            chart: {
              stacked: true,
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
              },
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: data.categories
            },
            colors: Object.values(colors),
          }

          this.chart.perYear.series = data.series;

        })
        .catch((error) => { console.log(error) })
    },

    onLoaded(){
      this.loaded = true;
      this.$vs.loading.close();
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }

    this.gridApi.sizeColumnsToFit();
  },
  watch: {
    // selected month
    selectedMonth(month){
      this.getData();
      this.getChartPerMonth();
    },

    selectedYear(year){
      this.getData();
      this.getChartPerMonth();
      this.getChartPerYear();
    },

    $route () {
      this.getData();
    },
    popup () {
      if (!this.popup) {
        this.form = {};
      }
    }
  },
  created () {
    const currentMonth = new Date().getMonth() + 1
    this.date.month.text = this.monthOptions.find((month) => month.value === currentMonth).text
    this.date.month.value = this.monthOptions.find((month) => month.value === currentMonth).value
    this.date.year = new Date().getFullYear();

    // set dynamic year options
    for (let i = new Date().getFullYear(); i >= 2014; i--) {
      this.yearOptions.push(i)
    }

    this.loading();
    this.getData();
    this.getChartPerMonth();
    this.getChartPerYear();
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
